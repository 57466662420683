

























import Vue from 'vue';

export default Vue.extend({
  name: 'Login',

  data: () => ({
    url: `https://${location.hostname}:${process.env.VUE_APP_BACKEND_PORT}${process.env.VUE_APP_BACKEND_URI}/auth/42/callback`,
    window: {
      width: 0,
      height: 0,
    },
  }),
});
